<template>
  <section>
    <div
    class="box h-full w-full"
    style="overflow:hidden;"
    >
      <div
      id="grafica"
      ref="elGrafica"
      style="height:450px;"
      class="w-full"
      />
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { apiPatologia } from '../../services'
import currency from 'currency.js'

import { useStore } from 'vuex'

const navegadorVentanaAncho = 1024
export default {
  name: 'GraficaPatologia',
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()

    /*
    * Refs and reactives
    */
    const loading = ref(false)
    const elGrafica = ref(null)
    let chartInstance = reactive({})
    const laData = ref([])
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    const getChartLabels = () => {
      return laData.value.map(a => a.label)
    }
    const getData = () => {
      return laData.value.map(a => a.total)
    }
    const setLaData = (values) => {
      laData.value = values
    }
    const initChart = () => {
      chartInstance = echarts.init(elGrafica.value, isDark.value ? 'dark' : 'light')
      const option = {
        backgroundColor: isDark.value ? '#313A55' : '',
        title: {
          text: 'Consumo por Patología',
          top: 15,
          left: 15
        },
        grid: {
          left: 20,
          right: 20,
          bottom: 15,
          top: 50,
          containLabel: true
        },
        tooltip: {
          formatter (a) {
            let row = a.name
            if (window.innerWidth < navegadorVentanaAncho) {
              row = laData.value[+a.name]
              return `<div style="width:140px; white-space: pre-wrap;">${row.label}</div>`
            }
            return row
          }
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLabel: { // Configuraciones relacionadas para etiquetas de escala de eje de coordenadas.
            interval: 0,
            // rotate: 90,
            formatter (a) {
              return `${currency(a / 1000000, { precision: 0, separator: '.' }).format()}M`
            }
          }
        },
        yAxis: {
          type: 'category',
          data: getChartLabels(),
          axisLabel: {
            fontSize: 8
              // showMinLabel: 8
              // formatter: (params) => {
              //     // Devuelve la cadena final
              //     return truncate(params, { length: 125, separator: '...' })
              // }
            }
        }
      }
      // if (window.innerWidth < navegadorVentanaAncho) {
      //   option.yAxis.show = false
      //   option.yAxis.data = []
      //   option.xAxis.show = false
      // }
      chartInstance.setOption(option)
      loadEvents()
    }

    watch(props.filters, (filter) => {
      if ([null, undefined, ''].includes(filter.client) ||
        [null, undefined, ''].includes(filter.year)
      ) return
      fetchResults(filter)
    })

    const fetchResults = (filter) => {
      loading.value = true
      apiPatologia(filter).then(({ data }) => {
        setLaData(data.reverse())
        setChart()
      }).catch((err) => {
        console.log(err.message)
      }).finally(() => {
        loading.value = false
      })
    }
    /*
    * Methods
    */
    const setChart = () => {
      const option = {
        xAxis: { show: true },
        yAxis: {
          show: true,
          type: 'category',
          data: getChartLabels(),
          label: {
            fontSize: 6
          }
        },
        series: [
          {
            data: getData(),
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'right',
              fontSize: 8,
              // color: 'white',
              formatter (d) {
                return `${currency(d.value / 1000000, { precision: 0, separator: '.' }).format()}M`
              }
            }
          }
        ]
      }

      if (window.innerWidth < navegadorVentanaAncho) {
        option.yAxis.show = false
        option.yAxis.data = []
        option.xAxis.show = false
      }

      chartInstance.setOption(option)
    }

    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        setChart()
        chartInstance.resize()
      })
    }

    /*
    * Hooks
    */
    onMounted(() => {
      initChart()
    })
    return {
      elGrafica
    }
  }
}
</script>

<style>

</style>
