<template>
  <section>
    <div class="box w-full" style="overflow:hidden;">
      <div
      id="grafica"
      ref="elGrafica"
      style="height:550px;"
      class="w-full"
      />
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { apiTopDoctors } from '../../services'
// import { truncate } from 'lodash'
import { useStore } from 'vuex'
import currency from 'currency.js'

export default {
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    watch(props.filters, (filter) => {
      if ([null, undefined, ''].includes(filter.client) ||
        [null, undefined, ''].includes(filter.year)
      ) return
      fetchMedicos(filter)
    })
    /*
    * Refs and reactives
    */
    const gradesRotation = ref(0)
    const positionLabel = ref('insideTop')
    const distanceLabel = ref(10)
    const colorLabel = ref('white')
    const elGrafica = ref(null)
    let chart = reactive({})
    const chartData = ref([])
    /*
    * Methods
    */
    const initChart = () => {
      gradesRotation.value = (window.innerWidth < 1000) ? 90 : 0
      positionLabel.value = (window.innerWidth < 1000) ? 'top' : 'top'
      distanceLabel.value = (window.innerWidth < 1000) ? 25 : 10
      colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
      chart = echarts.init(elGrafica.value, isDark.value ? 'dark' : 'light')
      const option = {
        backgroundColor: isDark.value ? '#313A55' : '',
        title: {
          top: 15,
          left: 15,
          text: 'Top 10 Médicos'
        },
        grid: {
          left: -50,
          right: 15,
          bottom: 50,
          top: 85,
          containLabel: true
        },
        toolbox: {
          top: 15,
          right: 15,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            saveAsImage: {}
          }
        },
        tooltip: {
          top: 0,
          formatter (a) {
            return `${a.name.split(' ').join('<br>')} <br> ${currency(a.value, { precision: 0, separator: '.' }).format()}`
          }
        },
        legend: {
          show: false,
          data: ['Medicos']
        },
        yAxis: [
          {
            show: false
          }
        ],
        xAxis: {
          data: getChartLabels(),
          axisLabel: {
            interval: 0,
            fontSize: 10,
            width: 60,
            overflow: 'break'
            // formatter (value) {
            //   return value.split(' ').join('\n')
            // }
          }
        },
        series: [
          {
            name: 'Medicos',
            type: 'bar',
            showBackground: true,
            data: getChartData(),
            label: {
              show: true,
              color: colorLabel.value,
              formatter (d) {
                return `${currency(d.value / 1000000, { precision: 0, separator: '.' }).format()}M`
              },
              position: positionLabel.value,
              rotate: gradesRotation.value,
              verticalAlign: 'middle',
              distance: distanceLabel.value
            }
          }
        ]
      }
      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }
      chart.setOption(option)
      loadEvents()
    }
    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        gradesRotation.value = (evt.target.innerWidth < 1000) ? 90 : 0
        positionLabel.value = (evt.target.innerWidth < 1000) ? 'top' : 'top'
        distanceLabel.value = (evt.target.innerWidth < 1000) ? 25 : 10
        colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
        reloadChart()
        chart.resize()
      })
    }

    const reloadChart = () => {
      const option = {
        xAxis: {
          data: getChartLabels(),
          show: true
        },
        series: [
          {
            showBackground: true,
            name: 'Medicos',
            type: 'bar',
            data: getChartData(),
            label: {
              show: true,
              color: colorLabel.value,
              formatter (d) {
                return `${currency(d.value / 1000000, { precision: 0, separator: '.' }).format()}M`
              },
              position: positionLabel.value,
              rotate: gradesRotation.value,
              verticalAlign: 'middle',
              distance: distanceLabel.value
            }
          }
        ]
      }

      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }

      chart.setOption(option)
    }

    const getChartData = () => {
      return chartData.value.map(a => a.Total)
    }

    const getChartLabels = () => {
      return chartData.value.map(a => a.Medico)
    }
    const setChartData = (values = []) => {
      chartData.value = values
    }
    const fetchMedicos = (filters) => {
      return apiTopDoctors(filters).then(({ data, status }) => {
        setChartData(data)
        reloadChart()
      }).catch((err) => {
        console.error(err.message)
      })
    }

    /*
    * Hooks
    */
    onMounted(() => {
      initChart()
    })

    return {
      chart,
      elGrafica
    }
  }
}
</script>

<style>

</style>
