
<template>
  <section>
    <div class="card border-b">
      <Divider align="left" type="dashed" id="divider-ips">
        <b>Ips</b>
      </Divider>

      <DataTable
        :value="customers1"
        v-model:expandedRows="expandedRows"
        :loading="loading"
        responsiveLayout="scroll"
        showGridlines
        scrollHeight="380px"
        class="p-datatable-sm"
        :scrollable="true"
      >
        <template #empty>No se encontraron entidades</template>
        <template #loading>Cargando...</template>
        <Column :expander="true" headerStyle="width: 10%" style="min-width: 10%; font-size:11px;"/>
        <Column field="Entidad" header="IPS"  style="min-width: 60%; font-size:11px;">
          <template #body="{data}">{{data.Entidad}}</template>
        </Column>
        <Column header="Total" headerStyle="width: 30%; justify-content: center" bodyStyle="justify-content:flex-end ; overflow: visible;min-width: 30%;" style="min-width: 30%; font-size:11px;">
          <template #body="{data}">{{data.totalFormat}}</template>
        </Column>
        <template #expansion="{data}">
          <div class="orders-subtable" style="width: 100%">
            <DataTable
              :value="data.children"
              showGridlines
              :scrollable="true"
              scrollHeight="150px"
              class="p-datatable-sm"
            >
              <Column header="Medico" headerStyle=""  style="min-width: 70%; font-size:11px;">
                <template #body="{data}">
                  {{data.Medico}}
                </template>
              </Column>
              <Column header="Total" headerStyle="width: 30%; text-align: center" bodyStyle="text-align: right; overflow: visible; justify-content: flex-end;" style="font-size:11px; min-width: 30%">
                <template #body="{data}">
                  {{data.totalFormat}}
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
  </section>
</template>
<script>
import { ref, onMounted } from 'vue'
import { apiTopIps } from '../../../../services'
export default {
  name: 'VisualizarTablaMedicos',
  props: {
    client: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    onMounted(() => {
      loading.value = true
      apiTopIps({ client: props.client, limit: false }).then(({ data }) => {
        customers1.value = data
        loading.value = false
      }).catch((e) => {
        console.error(e)
      })
    })
    const customers1 = ref([])
    const expandedRows = ref([])
    const loading = ref(false)
    return { customers1, loading, expandedRows }
  }
}
</script>
