<template>
  <section class="vizualizar-detalle">
    <Sidebar
    v-model:visible="visible"
    :baseZIndex="1000"
    :position="sidebarPosition"
    class="dark:bg-dark-1"
    :class="sidebarClass"
    >
      <Ips style="width: 100%;" :client="props.client"/>
      <Doctors style="width: 100%;" :client="props.client"/>
      <div class="w-full mb-3">
        <TopTecnicoVigilancia :client="props.client" />
      </div>
      <div class="w-full mb-3">
        <TopFarmacoVigilancia :client="props.client" />
      </div>
      <div class="w-full mb-3">
        <TopFarmacoVigilanciaMayor :client="props.client" />
      </div>
      <div class="w-full mb-3">
        <TopFarmacoVigilanciaDescripcion :client="props.client" />
      </div>
      <div class="w-full mb-3">
        <TopPoliformulados :client="props.client" />
      </div>
    </Sidebar>
  </section>
</template>
<script>
import { ref } from 'vue'
import Doctors from './components/medicos'
import Ips from './components/ips'
import TopTecnicoVigilancia from './components/topTecnoVigilancia'
import TopFarmacoVigilancia from './components/topFarmacoVigilancia'
import TopFarmacoVigilanciaMayor from './components/topFarmacoVigilanciaMayor'
import TopFarmacoVigilanciaDescripcion from './components/topFarmacoVigilanciaDescripcion'
import TopPoliformulados from './components/topPoliformulados'

export default {
  name: 'VizualizarDetalle',
  components: {
    Doctors,
    Ips,
    TopTecnicoVigilancia,
    TopFarmacoVigilancia,
    TopFarmacoVigilanciaMayor,
    TopFarmacoVigilanciaDescripcion,
    TopPoliformulados
  },
  props: {
    client: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const visible = ref(false)
    const sidebarPosition = ref(window.innerWidth > 1024 ? 'right' : 'full')
    const sidebarClass = ref(window.innerWidth > 1024 ? 'p-sidebar-md' : '')
    /** methods **/
    // eslint-disable-next-line no-return-assign
    const toggle = () => visible.value = !visible.value
    /** returns **/

    return {
      visible,
      toggle,
      sidebarPosition,
      props,
      sidebarClass
    }
  }
}
</script>
