<template>
  <section class="w-full">
    <div class="w-full flex justify-between items-center my-3">
      <h1 class="font-bold text-lg capitalize">Informe Ejecución</h1>
      <button
        type="button"
        class="bg-primary-1 text-white px-3 py-2 rounded-md "
        @click="handleDownloadPdf"
      >
        Descargar PDF
      </button>
    </div>
    <FiltrosEjecucion class="mt-2 mb-4 h-full" v-model="filters"/>
    <ResumenTotales class="my-2" :filters="filters"/>
    <resourcesByClient class="my-2" :filters="filters"/>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-2 mt-4">
      <GraficaAutorizacion :filters="filters"/>
      <MapaColombia v-model="filters"/>
    </div>
    <div class="w-full mt-4 mb-2">
      <GraficaAcumulados :filters="filters"/>
    </div>
    <div class="w-full h-full flex flex-col mt-4 mb-2">
      <TopIps :filters="filters"/>
    </div>
    <div class="w-full h-full flex flex-col my-2">
      <TopMdicos class="my-2" :filters="filters" />
    </div>
    <h1 class="font-bold text-lg capitalize">Tecnovigilancia</h1>
    <div class="w-full my-3">
      <CardsTecnoFarma :filters="filters" />
    </div>
    <div class="w-full my-3">
      <TecnicoVigilancia :filters="filters" />
    </div>
    <!-- <div class="w-full my-3">
      <TopTecnicoVigilancia :filters="filters" />
    </div> -->
    <h1 class="font-bold text-lg capitalize">Farmacovigilancia</h1>
    <div class="w-full my-3">
      <CardsFarma :filters="filters" />
    </div>
    <div class="w-full my-3">
      <FarmacoVigilancia :filters="filters" />
    </div>
    <h1 class="font-bold text-lg capitalize">Auditoría Médica</h1>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 my-2 mt-4">
      <PacientesDiagnostico :filters="filters"/>
      <PacientesPoliformulados :filters="filters"/>
      <AnalisisPoliPacientes :filters="filters"/>
    </div>
    <!-- <div class="w-full my-3">
      <TopFarmacoVigilancia :filters="filters" />
    </div> -->
  </section>
</template>

<script>
import FiltrosEjecucion from './components/filtros'
import TopIps from './components/topIps'
import TopMdicos from './components/topMedicos'
import GraficaAutorizacion from './components/graficaAutorizacion'
import GraficaAcumulados from './components/graficaAcumulados'
import ResumenTotales from './components/resumenTotales'
import MapaColombia from './components/mapaColombia'
import { reactive } from 'vue'
import axios from 'axios'
import TecnicoVigilancia from './components/tecnicoVigilancia'
import FarmacoVigilancia from './components/farmacoVigilancia'
// import TopTecnicoVigilancia from './components/topTecnicoVigilancia'
// import TopFarmacoVigilancia from './components/topFarmacoVigilancia'
import CardsTecnoFarma from './components/cardsTecnoFarma'
import CardsFarma from './components/cardsFarma'
import PacientesDiagnostico from './components/AuditoriaMedica/PacientesDiagnostico'
import PacientesPoliformulados from './components/AuditoriaMedica/PacientesPoliformulados'
import AnalisisPoliPacientes from './components/AuditoriaMedica/AnalisisPoliPacientes'
import resourcesByClient from './components/ReporteEjecutivo/index.vue'

export default {
  components: {
    FiltrosEjecucion,
    TopIps,
    TopMdicos,
    GraficaAutorizacion,
    GraficaAcumulados,
    ResumenTotales,
    MapaColombia,
    TecnicoVigilancia,
    FarmacoVigilancia,
    // TopTecnicoVigilancia,
    // TopFarmacoVigilancia,
    CardsTecnoFarma,
    CardsFarma,
    PacientesDiagnostico,
    PacientesPoliformulados,
    AnalisisPoliPacientes,
    resourcesByClient
  },
  setup () {
    const filters = reactive({
      year: new Date().getFullYear(),
      month_start: '01',
      month_end: '12',
      department: null,
      service: null,
      client: null
    })
    const handleDownloadPdf = () => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-pdf`
      const res = axios.getUri({ url, params: filters })
      if (filters.client !== null) {
        window.open(res, '_blank')
      }
    }
    return {
      filters,
      handleDownloadPdf
    }
  }
}
</script>
