<template>
  <section class="w-full box p-4" id="filtro-ejecucion">
    <div v-if="loading" class="rounded-md icono-carga flex justify-center items-center">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)"/>
    </div>
    <div class="accordion-item" v-else>
      <div id="faq-accordion-content-1" class="accordion-header flex justify-between">
        <button class="accordion-button text-base font-medium focus:outline-none text-primary-1 dark:text-white" type="button" data-bs-toggle="collapse"
                data-bs-target="#faq-accordion-collapse-1" aria-expanded="true"
                aria-controls="faq-accordion-collapse-1">
          Filtros de busqueda
        </button>
        <button class="btn btn btn-warning" @click="openModalVizualizarDetalle(filters.client)">
          Visualizar detalle
        </button>
      </div>
      <div id="faq-accordion-collapse-1" class="accordion-collapse collapse show mt-2"
           aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
        <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
          <div class="grid grid-cols-6 gap-x-6 gap-y-2">
            <div class="col-span-6 sm:col-span-2">
              <label for="client" class="block text-sm font-medium text-gray-700">Clientes:</label>
              <select class="form-select form-select-sm mt-1" id="client" v-model="filters.client">
                <option :value="null">-- Seleccione una opción --</option>
                <option v-for="item in Client" :key="item"> {{ item }}</option>
              </select>
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label for="department" class="block text-sm font-medium text-gray-700">Departamentos:</label>
              <select class="form-select form-select-sm mt-1" id="department" v-model="filters.department">
                <option :value="null">-- Seleccione una opción --</option>
                <option v-for="item in Departament" :key="item"> {{ item }}</option>
              </select>
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label class="block text-sm font-medium text-gray-700">Servicios:</label>
              <Multiselect class="mt-1"
                           placeholder="-- Seleccione una opción --"
                           v-model="filters.service"
                           mode="tags"
                           :searchable="true"
                           :options="Service"
              />
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label for="start_year" class="block text-sm font-medium text-gray-700">Año:</label>
              <select class="form-select form-select-sm mt-1" id="start_year" v-model="filters.year">
                <option :value="null">-- Seleccione una opción --</option>
                <option v-for="(row,k) in Year" :key="k" :value="row">{{ row }}</option>
              </select>
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label for="start_month" class="block text-sm font-medium text-gray-700">Mes Inicial:</label>
              <select class="form-select form-select-sm mt-1" id="start_month" v-model="filters.month_start">
                <option :value="null">-- Seleccione una opción --</option>
                <option v-for="(row,k) in months" :key="k" :value="row.id">{{ row.name }}</option>
              </select>
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label for="end_month" class="block text-sm font-medium text-gray-700">Mes Final:</label>
              <select class="form-select form-select-sm mt-1" id="end_month" v-model="filters.month_end">
                <option :value="null">-- Seleccione una opción --</option>
                <option v-for="(row,k) in filterMonths" :key="k" :value="row.id">{{ row.name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VizualizarDetalle ref="verDetalle" :client="filters.client" />
  </section>
</template>

<script>
import { warning } from '../../../../../../../libs/mensajes'
import { Client, Service, Departament, Year, loading, getFilters } from './hook'
import { onMounted, computed, ref } from 'vue'
import VizualizarDetalle from '../vizualizarDetalle'
export default {
  name: 'FilterEjecucion',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeMonthInitial (event) {
      console.log(event.target.value)
    }
  },
  components: {
    VizualizarDetalle
  },
  setup (props, context) {
    const months = [
      { id: '01', name: 'Enero' },
      { id: '02', name: 'Febrero' },
      { id: '03', name: 'Marzo' },
      { id: '04', name: 'Abril' },
      { id: '05', name: 'Mayo' },
      { id: '06', name: 'Junio' },
      { id: '07', name: 'Julio' },
      { id: '08', name: 'Agosto' },
      { id: '09', name: 'Septiembre' },
      { id: '10', name: 'Octubre' },
      { id: '11', name: 'Noviembre' },
      { id: '12', name: 'Diciembre' }
    ]
    const filterMonths = computed(() => {
      return months.filter((itemMonth) => {
        return parseInt(props.modelValue.month_start) <= parseInt(itemMonth.id)
      })
    })
    onMounted(getFilters)
    const verDetalle = ref()
    const openModalVizualizarDetalle = (cliente) => {
      if (cliente) {
        verDetalle.value.toggle()
      } else {
        warning('Seleccione un cliente')
      }
    }
    return {
      filters: props.modelValue,
      Client,
      Service,
      Departament,
      Year,
      loading,
      months,
      filterMonths,
      openModalVizualizarDetalle,
      verDetalle
    }
  }
}
</script>

<style scoped>
 @media print{
   #filtro-ejecucion{
     display: none;
   }
 }
</style>
