<template>
    <section id="resume-totales" class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 font-bold lg:grid-cols-5 gap-4">

      <div class="box bg-gray-400 p-2 rounded-md flex flex-col  w-full ">
        <h2 class="text-lg font-medium w-full text-center truncate text-black dark:text-white">
          {{totals.totalFacturadoFormat || 0 }}
        </h2>
        <div class="capitalize text-center text-gray-700 dark:text-white">
          Total facturado
        </div>
      </div>
      <div class="box bg-gray-400 p-2 rounded-md flex flex-col items-center h-full justify-center">
        <h2 class="text-lg font-medium text-center truncate text-black dark:text-white">
          {{totals.totalOrdenesFormat || 0}}
        </h2>
        <div class="capitalize text-center text-sm text-gray-700 dark:text-white">
          Total ordenes
        </div>
      </div>
      <div class="box bg-gray-400 p-2 rounded-md flex flex-col items-center justify-center">
        <h2 class="text-lg font-medium text-center truncate text-black dark:text-white">
          {{totals.poblacionFormat || 0}}
        </h2>
        <div class="capitalize text-center text-gray-700 dark:text-white">
          Población atendida
        </div>
      </div>
      <div class="box bg-gray-400 p-2 rounded-md flex flex-col items-center justify-center">
        <h2 class="text-lg font-medium text-center text-black dark:text-white">
          {{totals.mipresAsignadoFormat || 0}}
        </h2>
        <div class="capitalize text-center text-gray-700 dark:text-white">
          Mipres asignados
        </div>
      </div>
      <div class="box bg-gray-400 p-2 rounded-md flex flex-col items-center justify-center">
        <h2 class="text-lg font-medium text-center text-black dark:text-white">
          {{totals.mipresFacturadoFormat || 0}}
        </h2>
        <div class="capitalize text-center text-gray-700 dark:text-white">
          Mipres reportados
        </div>
      </div>

    </section>
  </template>

  <script>
  import { ref, watch } from 'vue'
  import { resourcesByClient } from './services/service.js'

  export default {
    props: {
      filters: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const totals = ref({
        poblacion: 0,
        poblacionFormat: '0',
        totalFacturado: 0,
        totalFacturadoFormat: '$0',
        totalOrdenes: 0,
        totalOrdenesFormat: '0'
      })

      watch(props.filters, (filter) => {
        if ([null, undefined, ''].includes(filter.client) ||
          [null, undefined, ''].includes(filter.year)
        ) return
        fetchTotales(filter)
      })

      const fetchTotales = (filters) => {
        return resourcesByClient(filters).then(({ data, status }) => {
          console.log('data--->', data)
          if (status === 200) {
            totals.value = data
          }
        }).catch((err) => {
          console.error(err.message)
        })
      }

      return {
        totals
      }
    }
  }
  </script>

  <style>
   @media print {
     #resume-totales{
       grid-template-columns: repeat(2, minmax(0, 1fr));
       display: grid;
     }
   }
   .bg-indicador{
     background: #bbbbbb94;
   }

  </style>
